import {MetaMaskProvider} from "metamask-react"
import {TxMgrProvider} from "./compont/tx"
import App from "./App"

export default function Web3App() {
    return (
        <>
            <MetaMaskProvider>
                <TxMgrProvider>
                    <App />
                </TxMgrProvider>
            </MetaMaskProvider>
        </>
    )
}
