interface StatusInfo {
    status: string
}

function Status({status}: StatusInfo) {
    let render

    switch (status) {
        case "connecting":
            render = <>🟡</>
            break
        case "connected":
            render = <>🟢</>
            break
        case "unavailable":
            render = <>🔴 无法连接 Metamask </>
            break
        default:
            render = <>⚪️</>
    }

    return render
}

export default Status
