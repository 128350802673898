import {useMetaMask} from "metamask-react"
import {ChainConfig} from "../config/chain"
import abi from "../abi/sai-wallet.json"
import Web3 from "web3"
import {AbiItem} from "web3-utils"
import {Contract} from "web3-eth-contract"
import {IMetaMaskContext} from "metamask-react/src/metamask-context"

type Web3Obj = {
    rpc?: Web3
    support: boolean
    config?: any
    swContract?: Contract | undefined
} & IMetaMaskContext

export function useWeb3(): Web3Obj {
    const meta = useMetaMask()
    if (meta.status !== "connected") {
        return {support: false, ...meta}
    }
    const config = ChainConfig.get(meta.chainId)
    if (!config) {
        return {support: false, ...meta}
    }
    const rpc = new Web3(config.url)
    const swContract = new rpc.eth.Contract(abi.abi as AbiItem[], config.saiWalletContract)
    return {rpc, support: true, ...meta, config, swContract}
}
