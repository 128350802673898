import Option, {OptionValue} from "./option"
import {Button, Space, message} from "antd"
import {useTxMgr} from "../tx"
import {useWeb3} from "../web3"
import {useState} from "react"
import Web3 from "web3"

interface TriggerRtn {
    data?: string
    error?: string
}

type LauncherTriggerFc = (swContract: any, select: any, option: OptionValue) => TriggerRtn

type LauncherProps = {
    onTrigger: LauncherTriggerFc
    showOption?: boolean
}

function Launcher({onTrigger, showOption = true}: LauncherProps) {
    let option: OptionValue = {perValue: 0, failedCase: 0}

    const [loading, setLoading] = useState(false)

    const {swContract} = useWeb3()
    // @ts-ignore
    const {sender, contractAddress, select} = useTxMgr()

    const onClick = async () => {
        const {data, error} = await onTrigger(swContract, select, option)
        if (error !== undefined) {
            message.error(error).then()
            return
        }
        setLoading(true)
        const {tx} = sender({
            to: contractAddress,
            data: data,
            value: Web3.utils.toBN(select.address?.length).muln(option.perValue),
            maxPriorityFeePerGas: null,
            maxFeePerGas: null
        })
        tx.on("transactionHash", () => {
            setLoading(false)
        })
            .on("error", () => {
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Space direction="vertical" size={"middle"}>
                {showOption ? (
                    <Option
                        onChange={(value) => {
                            option = value
                        }}
                    />
                ) : (
                    <></>
                )}
                <Button
                    type={"primary"}
                    size={"large"}
                    onClick={() => {
                        onClick()
                    }}
                    loading={loading}
                    style={{width: "150px"}}
                    htmlType={"submit"}
                >
                    发送
                </Button>
            </Space>
        </>
    )
}

export default Launcher
export type {LauncherTriggerFc, TriggerRtn}
