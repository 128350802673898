export const CHAINS = [{
    id: 56,
    url: "https://bscrpc.com",
    name: "Bsc",
    blockExplorerUrl: "https://bscscan.com/",
    saiWalletContract: "0x9134d5159fE26C538C0ec3fBeB5F28cb49F26F89"
}, {
    id: 137,
    url: "https://polygon-rpc.com/",
    name: "Polygon",
    blockExplorerUrl: "https://polygonscan.com/",
    saiWalletContract: "0x63Fe2720b221c5BDd2408FC05340a80673fe3ffB"
}, {
    id: 80001,
    url: "https://rpc.ankr.com/polygon_mumbai",
    name: "Polygon Mumbai (testnet)",
    blockExplorerUrl: "https://mumbai.polygonscan.com",
    saiWalletContract: "0x0ba659516ED5bE84BFdF94CD94A24C13a27D67d6"
}]

export const ChainConfig = (() => {
    const m = new Map()
    CHAINS.forEach(value => {
        m.set("0x" + value.id.toString(16), value)
    })
    return m
})()

