import {useWeb3} from "../web3"
import {useCallback, useState} from "react"
import {Form, Input, InputNumber} from "antd"
import Launcher from "./launcher"
import {useForm} from "antd/lib/form/Form"

function Approve() {
    const {rpc} = useWeb3()

    const [form] = useForm()

    const [target, setTarget] = useState(undefined)
    const [amount, setAmount] = useState(0)
    const [spender, setSpender] = useState(undefined)

    const onClick = useCallback(
        async (contract, select, option) => {
            try {
                await form.validateFields()
            } catch (e) {
                console.log(e)
                return {error: "参数校验异常"}
            }

            let _data
            const d = rpc.eth.abi.encodeFunctionCall(
                {
                    constant: false,
                    inputs: [
                        {
                            name: "_spender",
                            type: "address"
                        },
                        {
                            name: "_value",
                            type: "uint256"
                        }
                    ],
                    name: "approve",
                    outputs: [
                        {
                            name: "success",
                            type: "bool"
                        }
                    ],
                    payable: false,
                    type: "function"
                },
                [spender, amount]
            )
            if (select.selectAll) {
                _data = contract.methods.invokeAll(target, d, option).encodeABI()
            } else {
                _data = contract.methods.invokeBatch(target, d, option, select.address).encodeABI()
            }
            return {data: _data}
        },
        [rpc, spender, amount, form, target]
    )

    return (
        <>
            <Form labelCol={{span: 4}} wrapperCol={{span: 16}} form={form}>
                <Form.Item
                    label={"代币地址"}
                    name={"tokenAddress"}
                    rules={[
                        {required: true, pattern: "^0x[a-fA-F0-9]{40}$", message: "错误的地址格式"}
                    ]}
                >
                    <Input
                        style={{
                            width: "400px"
                        }}
                        onChange={(e) => {
                            setTarget(e.target.value)
                        }}
                        placeholder={"0x8888...."}
                    />
                </Form.Item>
                <Form.Item
                    label={"被授权人地址"}
                    name={"spenderAddress"}
                    rules={[
                        {required: true, pattern: "^0x[a-fA-F0-9]{40}$", message: "错误的地址格式"}
                    ]}
                >
                    <Input
                        style={{
                            width: "400px"
                        }}
                        onChange={(e) => {
                            setSpender(e.target.value)
                        }}
                        placeholder={"0x8888...."}
                    />
                </Form.Item>
                <Form.Item label={"授权金额"} name={"approveAmount"}>
                    <Input
                        type={"number"}
                        controls={false}
                        style={{
                            width: "300px"
                        }}
                        onChange={(e) => {
                            setAmount(e.target.value ?? 0)
                        }}
                    />
                </Form.Item>
            </Form>
            <Launcher onTrigger={onClick} showOption={false} />
        </>
    )
}

export default Approve
