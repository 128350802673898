import {Tabs} from "antd"
import Custom from "./custom"
import Approve from "./approve"
import Collect from "./collect"

function Operate() {
    return (
        <div>
            <Tabs
                style={{
                    margin: "10px",
                    display: "flex",
                    justifyContent: "space-around"
                }}
                defaultActiveKey="custom"
                items={[
                    {
                        label: "自定义",
                        key: "custom",
                        children: <Custom />
                    },
                    {
                        label: "代币授权",
                        key: "approve",
                        children: <Approve />
                    },
                    {
                        label: "代币归集",
                        key: "collect",
                        children: <Collect />
                    }
                ]}
            />
        </div>
    )
}

export default Operate
