import {createContext, useCallback, useContext, useState} from "react"
import {useWeb3} from "./web3"
import Web3 from "web3"

const TxMgrContext = createContext({})

export const TxMgrProvider = (props) => {
    const {ethereum, config} = useWeb3()
    const [event, setEvent] = useState(undefined)
    const [selectAddress, setSelectAddress] = useState({})

    const changeSelectAddrs = (addrs) => {
        setSelectAddress(addrs)
    }

    const [txn, setTxn] = useState([])
    const addTx = useCallback(
        (t) => {
            const obj = {status: "sending", error: undefined}
            t.on("transactionHash", (hash) => {
                obj["hash"] = hash
                const tx = [...txn, obj]
                setTxn(tx)
            })
                .on("receipt", (receipt) => {
                    obj.status = "finish"
                    obj.tx = receipt

                    setEvent(obj)
                })
                .on("error", (error) => {
                    obj.status = "failed"
                    obj.error = error

                    setEvent(obj)
                })
        },
        [txn]
    )

    const _sendFn = (ethereum, addTx) => {
        return function (params) {
            // params["from"] = ethereum.selectedAddress

            // const hash = await ethereum.request({
            //     method: method, params: [params]
            // })
            const pTx = new Web3(ethereum).eth.sendTransaction({
                ...params,
                from: ethereum.selectedAddress
            })
            addTx(pTx)
            return {tx: pTx}
        }
    }

    return (
        <TxMgrContext.Provider
            value={{
                txn,
                sender: _sendFn(ethereum, addTx),
                event,
                changeSelectAddrs,
                select: selectAddress,
                contractAddress: config?.saiWalletContract
            }}
            {...props}
        />
    )
}

export function useTxMgr() {
    return useContext(TxMgrContext)
}
