import {InputNumber, Space, Radio, Tooltip} from "antd"
import {useState} from "react"

interface OptionValue {
    perValue: number
    failedCase: number
}

type onChangeFc = (value: OptionValue) => void

type OptionProps = {
    onChange: onChangeFc
}

// @ts-ignore
function Option({onChange}: OptionProps) {
    const [option, setOption] = useState<OptionValue>({
        perValue: 0,
        failedCase: 0
    })

    const valueChange = (value: number) => {
        const obj: OptionValue = {
            perValue: value ?? 0,
            failedCase: option.failedCase
        }
        setOption(obj)
        onChange(obj)
    }

    const checkboxChange = (cases: number) => {
        const obj: OptionValue = {
            perValue: option.perValue,
            failedCase: cases
        }
        setOption(obj)
        onChange(obj)
    }

    return (
        <>
            <Space size={"middle"}>
                <div>单次携带金额 :</div>
                <InputNumber
                    controls={false}
                    defaultValue={0}
                    min={0}
                    addonAfter={"wei"}
                    onChange={(e) => {
                        valueChange(e ?? 0)
                    }}
                    style={{width: "400px"}}
                />

                <Radio.Group
                    defaultValue={0}
                    buttonStyle="solid"
                    onChange={(e) => {
                        checkboxChange(e.target.value ?? 0)
                    }}
                >
                    <Tooltip title="单次失败时，忽略失败继续执行。">
                        <Radio.Button value={0}>忽略失败</Radio.Button>
                    </Tooltip>
                    <Tooltip title="单次失败时，停止执行回滚交易。">
                        <Radio.Button value={1}>失败时回滚</Radio.Button>
                    </Tooltip>
                    <Tooltip title="单次失败时，不继续执行。">
                        <Radio.Button value={2}>失败时结束</Radio.Button>
                    </Tooltip>
                </Radio.Group>
            </Space>
        </>
    )
}

export default Option
export type {OptionValue}
