import "./App.css"
import Network from "./compont/network/Network"
import {Col, Layout, Row} from "antd"
import {Content, Header} from "antd/es/layout/layout"
import WalletBot from "./compont/wallet/box"
import Txn from "./compont/wallet/txn"
import Operate from "./compont/operate/operate"

function App() {
    return (
        <>
            <Layout>
                <Header
                    style={{
                        backgroundColor: "white"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >
                        <div><h1>SaiWallet</h1></div>
                        <Network />
                    </div>
                </Header>
                <Content>
                    <Operate />
                    <Row>
                        <Col span={18}>
                            <WalletBot />
                        </Col>
                        <Col span={6}>
                            <Txn />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    )
}

export default App
