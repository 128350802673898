import {Button, Col, InputNumber, message, Popconfirm, Row, Space, Spin} from "antd"
import {useEffect, useState} from "react"
import {CheckCard} from "@ant-design/pro-components"
import {CopyOutlined} from "@ant-design/icons"
import {useWeb3} from "../web3"
import {LoadingOutlined} from "@ant-design/icons"
import {useTxMgr} from "../tx"

function WalletBot({onSelect}) {
    const {chainId, account, support, swContract} = useWeb3() ?? {}

    const {sender, changeSelectAddrs} = useTxMgr()

    const [addrs, setAddrs] = useState([])
    const [selectAddrs, setSelectAddrs] = useState([])
    const [selected, setSelected] = useState(true)
    const [createNum, setCreateNum] = useState(0)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadData().then()
    }, [chainId, account])

    const onChangeSelectAddrs = (newSelectAddrs, isSelectAll) => {
        setSelectAddrs(newSelectAddrs)
        onSelect?.(newSelectAddrs)
        changeSelectAddrs({
            address: newSelectAddrs,
            selectAll: isSelectAll
        })
    }

    const loadData = async function queryUserWallet() {
        if (!support) {
            setAddrs([])
            return
        }

        setLoading(true)
        const result = await swContract.methods.userWallet(account).call()
        setAddrs(result)
        onChangeSelectAddrs(selected ? result : [], true)
        setLoading(false)
    }

    const selectChange = () => {
        if (selected) {
            onChangeSelectAddrs([], true)
        } else {
            onChangeSelectAddrs(addrs, true)
        }
        setSelected(!selected)
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "5px"
                }}
            >
                <Space>
                    <div>共计 : {addrs?.length}</div>
                    <div>已选 : {selectAddrs?.length}</div>
                    <Button
                        onClick={() => {
                            selectChange()
                        }}
                        type={!selected ? "primary" : ""}
                    >
                        {selected ? "取消" : "全选"}
                    </Button>
                </Space>
                <Space>
                    <Button
                        onClick={() => {
                            loadData().then()
                        }}
                    >
                        刷新
                    </Button>

                    <InputNumber
                        controls={false}
                        value={createNum}
                        onChange={(v) => {
                            setCreateNum(v)
                        }}
                    />
                    <Popconfirm
                        onConfirm={async () => {
                            const data = swContract.methods.create(createNum).encodeABI()
                            const {tx} = sender({
                                to: swContract.options.address,
                                data: data
                            })
                            tx.finally(()=>{
                                loadData().then()
                            })
                        }}
                        disabled={createNum <= 0}
                        title={`是否创建 ${createNum} 个钱包地址 ?`}
                    >
                        <Button type={"primary"} disabled={createNum <= 0}>
                            创建钱包
                        </Button>
                    </Popconfirm>
                </Space>
            </div>

            {!loading ? (
                <CheckCard.Group
                    multiple
                    value={selectAddrs}
                    onChange={(selectValues) =>
                        onChangeSelectAddrs(selectValues, selectValues?.length === addrs?.length)
                    }
                    style={{
                        height: "500px",
                        overflow: "auto"
                    }}
                >
                    <Row>
                        {addrs.map((value, idx) => {
                            return (
                                <Col span={6}>
                                    <CheckCard
                                        title={
                                            <div
                                                style={{
                                                    WebkitUserSelect: "none"
                                                }}
                                            >
                                                # {idx + 1}{" "}
                                            </div>
                                        }
                                        value={value}
                                        description={
                                            <div
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    WebkitUserSelect: "none"
                                                }}
                                                unselectable={"on"}
                                            >
                                                {value}
                                            </div>
                                        }
                                        extra={
                                            <CopyOutlined
                                                style={{fontSize: 16}}
                                                onClick={(event) => {
                                                    navigator.clipboard.writeText(value).then()
                                                    message
                                                        .success(" copy address success ", 0.5)
                                                        .then()
                                                    event.stopPropagation()
                                                }}
                                            />
                                        }
                                        style={{
                                            margin: "5px",
                                            width: "90%"
                                        }}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </CheckCard.Group>
            ) : (
                <div
                    style={{
                        height: "500px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 80}} spin />}
                        size={"large"}
                    ></Spin>
                </div>
            )}
        </>
    )
}

export default WalletBot
