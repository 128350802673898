import {Button, Form, Switch} from "antd"
import Input from "antd/es/input/Input"
import {useCallback, useState} from "react"
import Launcher from "./launcher"
import {useWeb3} from "../web3"
import {useForm} from "antd/es/form/Form"

function Collect() {
    const {account} = useWeb3()
    const [isNativeToken, changeNativeToken] = useState(true)
    const [receiver, setReceiver] = useState(account)
    const [token, setToken] = useState(undefined)

    const [form] = useForm()

    const onTrigger = useCallback(
        async (contract, select, option) => {
            try {
                await form.validateFields()
            } catch (e) {
                return {error: "参数校验失败"}
            }

            let _data

            if (isNativeToken) {
                if (select.selectAll) {
                    _data = contract.methods.withdrawAll(receiver).encodeABI()
                } else {
                    _data = contract.methods.withdrawBatch(receiver, select.address).encodeABI()
                }
            } else {
                if (select.selectAll) {
                    _data = contract.methods.withdrawTokenAll(receiver, token).encodeABI()
                } else {
                    _data = contract.methods
                        .withdrawTokenBatch(receiver, token, select.address)
                        .encodeABI()
                }
            }
            return {data: _data}
        },
        [isNativeToken, form, receiver, token]
    )

    return (
        <>
            <Form labelCol={{span: 4}} wrapperCol={{span: 16}} form={form}>
                <Form.Item label={"原生代币"}>
                    <Switch
                        defaultChecked={true}
                        onChange={(e) => {
                            changeNativeToken(!isNativeToken)
                        }}
                    />
                </Form.Item>
                {!isNativeToken ? (
                    <Form.Item
                        label={"代币地址"}
                        name={"tokenAddress"}
                        rules={[
                            {
                                required: true,
                                pattern: "^0x[a-fA-F0-9]{40}$",
                                message: "错误的地址格式"
                            }
                        ]}
                    >
                        <Input
                            onChange={(e) => {
                                setToken(e.target.value)
                            }}
                            style={{
                                width: "400px"
                            }}
                        />
                    </Form.Item>
                ) : (
                    <></>
                )}
                <Form.Item
                    label={"接收地址"}
                    name={"receiver"}
                    rules={[
                        {
                            required: true,
                            pattern: "^0x[a-fA-F0-9]{40}$",
                            message: "错误的地址格式"
                        }
                    ]}
                    initialValue={account}
                >
                    <Input
                        onChange={(e) => {
                            setReceiver(e.target.value)
                        }}
                        style={{
                            width: "400px"
                        }}
                    />
                </Form.Item>
            </Form>
            <Launcher showOption={false} onTrigger={onTrigger} />
        </>
    )
}

export default Collect
