import {Form, Input} from "antd"
import TextArea from "antd/es/input/TextArea"
import {useCallback, useState} from "react"
import Launcher from "./launcher"

function Custom() {
    const [target, setTarget] = useState(undefined)
    const [data, setData] = useState("0x")

    const [form] = Form.useForm()

    const onTrigger = useCallback(
        async (contract, select, option) => {
            try {
                await form.validateFields()
            } catch (e) {
                return {error: "参数校验失败"}
            }
            let _data
            if (select.selectAll) {
                _data = contract.methods.invokeAll(target, data, option).encodeABI()
            } else {
                _data = contract.methods
                    .invokeBatch(target, data, option, select.address)
                    .encodeABI()
            }
            return {data: _data}
        },
        [target, data, form]
    )

    return (
        <>
            <Form labelCol={{span: 3}} wrapperCol={{span: 18}} form={form}>
                <Form.Item
                    label={"地址"}
                    name="address"
                    rules={[
                        {required: true, pattern: "^0x[a-fA-F0-9]{40}$", message: "错误的地址格式"}
                    ]}
                >
                    <Input
                        onChange={(v) => {
                            setTarget(v.target.value)
                        }}
                        style={{
                            width: "400px"
                        }}
                        placeholder={"0x8888..."}
                    />
                </Form.Item>
                <Form.Item label={"Data"} name={"data"}>
                    <TextArea
                        rows={4}
                        onChange={(v) => {
                            setData(v.target.value.length <= 0 ? "0x" : v.target.value)
                        }}
                        placeholder="0xabcd..."
                    />
                </Form.Item>
                <Launcher onTrigger={onTrigger} />
            </Form>
        </>
    )
}

export default Custom
