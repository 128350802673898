import {CHAINS} from "../../config/chain"
import {Button, Dropdown, Menu, Space, message} from "antd"
import Status from "./Status"
import {LinkOutlined} from "@ant-design/icons"
import {useWeb3} from "../web3"

const _maps = (() => {
    const m = new Map()

    CHAINS.forEach((v) => {
        m.set("0x" + v.id.toString(16), v)
    })
    return m
})()

const _networkItem = (() => {
    const items = []
    CHAINS.forEach((v) => {
        items.push({
            label: v.name,
            key: "0x" + v.id.toString(16)
        })
    })
    return items
})()

function Network() {
    const {status, switchChain, account, connect, chainId, config, support} = useWeb3()

    const connected = status === "connected"

    const accountText = connected
        ? account?.substring(0, 6) + "...." + account?.substring(account.length - 4, account.length)
        : ""
    return (
        <>
            <Space>
                {connected ? (
                    <>
                        <div
                            onClick={() => {
                                if (status === "connected" && account?.length > 0) {
                                    navigator.clipboard.writeText(account)
                                    message.success(" copy account success ", 0.5)
                                }
                            }}
                        >
                            {accountText}
                        </div>
                        <div style={{fontSize: 18}}>
                            <a
                                href={config?.blockExplorerUrl + `/address/${account}`}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <LinkOutlined />
                            </a>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <Dropdown
                    overlay={() => {
                        return (
                            <Menu
                                items={_networkItem}
                                onClick={(v) => {
                                    switchChain(v.key).then(
                                        () => {
                                            if (status === "notConnected") {
                                                connect().then()
                                            }
                                        },
                                        () => {
                                            message.error("连接 Metamask 异常", 1)
                                        }
                                    )
                                }}
                                style={{
                                    textAlign:"center"
                                }}
                            />
                        )
                    }}
                    trigger={["click"]}
                >
                    <Button>
                        <Space>
                            {support ? <Status status={status} /> : <></>}
                            {status === "connected"
                                ? _maps.get(chainId)
                                    ? _maps.get(chainId).name
                                    : " ❎ 未支持网络"
                                : " 🔗 "}
                        </Space>
                    </Button>
                </Dropdown>
            </Space>
        </>
    )
}

export default Network
