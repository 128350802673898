import {List, message, Space} from "antd"
import {useTxMgr} from "../tx"
import {useEffect} from "react"
import {useWeb3} from "../web3"
import {CopyOutlined} from "@ant-design/icons"

function Txn() {
    const {txn, event} = useTxMgr()

    useEffect(() => {
        if (!event) {
            return
        }
        console.log(event.error)
        if (event.error) {
            message.error(event.error.message)
        }
    }, [event])

    return (
        <List
            header={<div>交易记录</div>}
            bordered
            locale={{emptyText: "No Transactions record"}}
            dataSource={txn}
            style={{
                margin: "5px",
                width: "95%",
                height: "95%"
            }}
            renderItem={(item) => <TxnItem item={item} />}
        />
    )
}

function TxnItem({item}) {
    const {config} = useWeb3()

    let status = "🟡"
    switch (item.status) {
        case "finish":
            status = "🟢"
            break
        case "failed":
            status = "🔴"
            break
        default:
            break
    }

    return (
        <List.Item>
            <Space
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}
            >
                <div>{status}</div>
                <CopyOutlined
                    style={{fontSize: 16}}
                    onClick={(event) => {
                        navigator.clipboard.writeText(item.hash).then()
                        message.success(" copy hash success ", 0.5).then()
                        event.stopPropagation()
                    }}
                />
                <a
                    href={config.blockExplorerUrl + `/tx/${item.hash}`}
                    rel="noreferrer"
                    target={"_blank"}
                >
                    {item.hash}
                </a>
            </Space>
        </List.Item>
    )
}

export default Txn
